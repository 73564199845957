export default {
    "eu-footer": "Die beiden Projekte werden durch das Forschungs und Innovationsprogramm Horizont 2020 der Europäischen Union unter den Finanzhilfevereinbarungen Nr. 776559 (SecREEts) und Nr. 821114 (SUSMAGPRO) gefördert.",
    "buttons": {
        "submit": "Einreichen",
        "yes": "JA",
        "no": "NEIN"
    },
    "read less": "Weniger lesen",
    "read more": "Mehr lesen"
}
