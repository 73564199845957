
import {defineComponent} from 'vue';
import axios from "axios";
import 'chart.js/auto';
import {Bar} from "vue-chartjs";

export default defineComponent({
  name: 'AppStats',
  components: {
    Bar,
  },
  data() {
    return {
      sources: [
        // 'scirt.prospex-institute.org',
        'bioplastics.prospex-institute.org'
      ],
      source: '',
      langPrefix: '',
      filter1Options: [],
      filter2Options: [],
      filter1Key: '',
      filter2Key: '',
      filter1Value: '',
      filter2Value: '',
      locale: 'en',
      texts: {},
      questions: [],
      chartSeries: [
        {name: 'Age Group', filter: 'AgeGroup', prefix: 'AgeGroup'},
        {name: 'Gender', filter: 'Gender', prefix: 'Gender'},
      ],
      question: '',
      chartReady: false,
      chartData: {
        labels: [],
        datasets: []
      },
      chartOptions: {
        indexAxis: 'y',
        responsive: true
      }
    }
  },

  computed: {
    deviceClass() {
      const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
      // per browser class
      const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
      const isFirefox = navigator.userAgent.toLowerCase().indexOf('firefox') > -1;
      // @ts-ignore
      const isChrome = !!window.chrome && (!!window.chrome.app || !!window.chome.webstore || !!window.chrome.runtime);
      const isEdge = navigator.userAgent.indexOf("Edge") > -1;

      return {
        'is-mobile': isMobile,
        'is-safari': isSafari,
        'is-firefox': isFirefox,
        'is-chrome': isChrome,
        'is-edge': isEdge,
        'is-none': !isMobile && !isSafari && !isFirefox && !isChrome && !isEdge,
      }
    },

    filters() {
      const filters = {};
      if (this.filter1Key) {
        filters[this.filter1Key] = this.filter1Value;
      }
      if (this.filter2Key) {
        filters[this.filter2Key] = this.filter2Value;
      }
      return filters;
    }
  },

  mounted() {
    const urlParams = new URLSearchParams(window.location.search);
    console.log('AppStats mounted', urlParams);

    // if query param debug, add sources with -test in the subdomain eg. subdomain.domain.com -> subdomain-test.domain.com
    if (urlParams.get('test')) {
      this.sources.push('scirt.prospex-institute.org');
      const testSources = this.sources.map((source) => source.replace('.', '-test.'));
      this.sources.push(...testSources);
      this.sources.push('localhost');
    }

    this.source = this.sources[0];
    this.loadQuestions();

    // this.loadQuestions();
    // this.loadStats();
  },
  methods: {
    loadQuestions() {
      this.langPrefix = this.source.replace('-test', '').replace('prospex-institute.org', '');
      if (!this.langPrefix.endsWith('.')) {
        this.langPrefix = this.langPrefix + '.';
      }
      this.chartReady = false;
      this.questions = [];
      this.filter1Options = [];
      this.filter2Options = [];
      this.filter1Key = '';
      this.filter2Key = '';

      axios.post(process.env.VUE_APP_SUBMIT_API + '/pitool/stats', {source: this.source})
          .then((response) => {
            this.questions = response.data.questions;
            this.texts = response.data.texts;
            this.question = this.questions[0].question;
            this.loadStats();
          })
          .catch((error) => {
            console.log(error);
          });
    },

    loadStats() {
      this.chartReady = false;

      const hasFiltersEnabled = Object.keys(this.filters).length

      axios.post(process.env.VUE_APP_SUBMIT_API + '/pitool/summary', {
        'question': this.question,
        'filter': this.filters,
        source: this.source
      })
          .then((response) => {
            const answers = response.data.map((item) => item.answer).sort()
            const labels = this.toTranslatedLabels(answers);
            const values = answers.map((label) => response.data.find((item) => item.answer === label).count);

            this.chartData.labels = Object.values(labels);
            this.chartData.datasets = [{
              data: values,
              label: hasFiltersEnabled ? 'Data with filters' : 'Data',
              backgroundColor: '#6C8280',
            }];

            if (!hasFiltersEnabled) {
              this.chartReady = true;
            }
          })
          .catch((error) => {
            console.log(error);
          });

      if (Object.keys(this.filters).length) {
        axios.post(process.env.VUE_APP_SUBMIT_API + '/pitool/summary', {
          'question': this.question,
          // 'filter': this.filters,
          source: this.source
        })
            .then((response) => {
              const answers = response.data.map((item) => item.answer).sort()
              const values = answers.map((label) => response.data.find((item) => item.answer === label).count);

              this.chartData.datasets[1] = {
                data: values,
                label: 'All Data',
                backgroundColor: '#363535',
              };

              this.chartReady = false;
              this.$nextTick(() => {
                this.chartReady = true;
              })
            })
            .catch((error) => {
              console.log(error);
            });
      }
    },

    loadFilterData(filterProp) {
      this[filterProp + 'Options'] = [];

      if (!this[filterProp + 'Key']) {
        this.loadStats();
        return;
      }

      axios.post(process.env.VUE_APP_SUBMIT_API + '/pitool/summary', {
        question: this[filterProp + 'Key'],
        source: this.source
      })
          .then((response) => {
            this[filterProp + 'Options'] = this.toTranslatedLabels(response.data.map((item) => item.answer).sort());
            console.log(this[filterProp + 'Options'])
          })
          .catch((error) => {
            console.log(error);
          });
    },

    downloadData() {
      axios.get(`${process.env.VUE_APP_SUBMIT_API}/pitool/export?source=${this.source}`)
          .then((response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            let filename = response.headers['content-disposition']?.split(';')?.[1].split('=')?.[1];
            filename = filename?.replace(/"/g, '');
            link.setAttribute('download', filename ?? 'data.tsv'); //or any other extension
            document.body.appendChild(link);
            link.click();
          })
          .catch((error) => {
            console.log(error);
          });
    },

    toolTranslation(key) {
      if (this.texts?.[this.locale]?.[key] || this.texts?.['en']?.[key]) {
        return this.texts[this.locale][key] || this.texts['en'][key];
      }

      return this.$t(this.langPrefix + key.replace(/\./, '-'));
    },
    toTranslatedLabels(answers: string[]): object {
      let output = {};
      if (answers[0]?.startsWith('{')) {
        output = answers.reduce((out, ans) => {
          const subvalues = JSON.parse(ans)
          const newValues = Object.entries(subvalues).map(([key, value]) => [this.toolTranslation(key), value])

          // if values all true, then return list of keys
          if (newValues.every(([key, value]) => value === true || value === undefined)) {
            out[ans] = newValues.map(entry => entry[0]).join('; ')
            return out;
          }

          out[ans] = JSON.stringify(Object.fromEntries(newValues))
          return out;
        }, {})
      } else if (answers[0]?.startsWith('[')) {
        console.log('array', answers)
        output = answers.reduce((out, ans) => {
          const subvalues = JSON.parse(ans)
          const newValues = subvalues.map(value => this.toolTranslation(value));

          out[ans] = newValues.join('; ')
          return out;
        }, {})
      } else {
        console.log('string', answers)
        output = answers.reduce((out, ans) => {
          // if numeric value, then return as is
          if (!isNaN(ans as any)) {
            out[ans] = ans
            return out;
          }
          // if (!isNaN(ans)) {
          //   out[ans] = ans
          //   return out;
          // }

          out[ans] = this.toolTranslation(ans);
          return out;
        }, {})
      }

      return output;
    }
  }
});
