import {ScreensEnum} from "@/Screens.enum";

export default {
    "eu-footer": 'This project has received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement N°862674.',
    "buttons": {
        "submit": "Submit",
        "yes": "YES",
        "no": "NO",
        'next': 'Next',
        'back': 'Back',
        "start": "Let's start {name}!",
        "go": "Let's go {name}!",
        "see": "Let's see",
        "letsfindout": "Let's find out!",
        "true": "True",
        "false": "False",
        'iwanttoknowmore': 'I want to know more',
        'end-experience': 'End experience',
    },
    'texts': {
        'or': 'or',
        'and': 'and',
        'readmore': 'Read more',
    },

    'defaultNickname': 'Visitor',
    'data-disclaimer': 'Your data remains completely anonymous. The results of the survey will be freely available.',

    [ScreensEnum.GOGRASS_INTRO]: {
        'eu-footer': "This project has received funding from the European Union’s Horizon 2020 research and innovation programme under grant agreement N°862674"
    },

    'bioplastics': {
        WhichInformationIsNeeded: 'Which information would you need to decide whether the cutlery you are offered is a good sustainable choice?',
        WhereDoYouLive: 'Where do you live?',
        WhichAgeGroup: 'Which age group are you?',
        TestYouKnowledge: 'How (un)sustainable is plastic cutlery? Let\'s test your knowledge...',
        SustainableSymbols: 'If a plastic product has this sign on it, how assuring is it for you that the product is sustainable?',
        SustainabilityPriorityPrice: 'Considering disposable cutlery, how willing are you to prioritize sustainability over price and convenience in your choices?',
        SustainabilityPriorityConvenience: 'Considering disposable cutlery, how willing are you to prioritize sustainability over price and convenience in your choices?',
        'OccasionsToSwitch-takeaway': 'For which occasion and usage would you consider switching to multiple-use recyclable plastic cutlery? - Take away food',
        'OccasionsToSwitch-restaurant': 'For which occasion and usage would you consider switching to multiple-use recyclable plastic cutlery? - Restaurant (eat on site)',
        'OccasionsToSwitch-fooddelivery': 'For which occasion and usage would you consider switching to multiple-use recyclable plastic cutlery? - Food delivery',
        'OccasionsToSwitch-prepareforthego': 'For which occasion and usage would you consider switching to multiple-use recyclable plastic cutlery? - Prepare food at home and take it to go',
        'OccasionsToSwitch-picnic': 'For which occasion and usage would you consider switching to multiple-use recyclable plastic cutlery? - Outdoor event (picnic)',
        'OccasionsToSwitch-countryside': 'For which occasion and usage would you consider switching to multiple-use recyclable plastic cutlery? - Countryside',
        'OccasionsToSwitch-homeparty': 'For which occasion and usage would you consider switching to multiple-use recyclable plastic cutlery? - At home (party)',
        'HowOfterYouUseDisposable': 'How often do you use disposable cutlery, whether single-use or multiple-use?',
        'DoYouUseDisposable': 'Do you use disposable cutlery? Which type?',
        BestDescribeYourUse: 'Which statement best describes your use of disposable cutlery? (you can select more options)',

        'eurecycles': 'Plastic waste produced in the EU is usually recycled.',
        'biobasedmaterials': 'Plastic cutlery can be made of biobased materials.',
        'halfissingleuse': "More than 50% of waste in the sea and environment is single-use plastic.",
        'singleusebanned': 'Single-use plastic cutlery is banned in the EU.',
        'dontthink': 'I don\'t think about it',
        'itspractical': 'It is practical and easy to use',
        'avoidifinconvenient': 'I avoid it if it doesn\'t cause inconvenience',
        'activelyavoid': 'I actively try to avoid it',
        'sometimesbuy': 'I sometimes buy it (a single piece or a pack)',
        'usewithmealorevent': 'I mostly only use it when I get it with a meal or at an event',
        'daily': 'Daily',
        'weekly': 'Weekly',
        'monthly': 'Monthly',
        'lessmonthly': 'Less than monthly',
        'notatall': 'Never',
        'unsure': 'Unsure',
        'idont': 'I use neither',
        'singleuse': 'Single-use',
        'multipleuse': 'Multiple-use',
        'both': 'Both',
        'city': 'City',
        'suburbs': 'Suburbs',
        'countryside': 'Countryside',
        'notassuring': 'Not assuring at all',
        'slightly': 'Slighly assuring',
        'moderately': 'Moderately assuring',
        'assuring': 'Assuring',
        'veryassuring': 'Very assuring',
        'righttext': "This logo means that the product fulfils strict\nconditions on how it is produced, processed,\ntransported and stored. The logo can only be used\non products when they contain at least 95% organic\ningredients and additionally, respect further strict\nconditions for the remaining 5%.",
        '1-20': '1 - 20',
        '21-40': '21 - 40',
        '41-60': '41 - 60',
        '61+': '61+',
        'retailerinfo': 'Info from the retailer/restaurant',
        'packaginginfo': 'Info on the packaging',
        'symbol': 'A recognizable symbol on the cutlery',
        'ban': 'Ban of unsustainable options',
    },

    ['WhichAgeGroup']: {
        'under-18': 'Under 18 years',
        '18-30': '18 - 30 years',
        '31-40': '31 - 40 years',
        '41-60': '41 - 60 years',
        '60+': 'Above 60 years',
    },
    [ScreensEnum.HaveYouEverBoughtAlternative]: {
        yes: 'Yes, I have',
        no: 'No, never',
        notsure: 'I am not sure',
        prefertosticktoknown: 'I prefer to stick to what I know',
    }
};
