import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-77dd309a"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["disabled"]
const _hoisted_2 = ["innerHTML"]
const _hoisted_3 = ["src"]
const _hoisted_4 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("button", {
    class: _normalizeClass([`action-button ${_ctx.buttonClass} ${_ctx.colorClass} ${_ctx.selected ? 'active' : ''}`, "relative"]),
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.click())),
    disabled: _ctx.disabled,
    type: "button"
  }, [
    (_ctx.preicon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 0,
          class: "mr-2 opacity-50",
          style: {"font-size":"1.5rem","line-height":"1","vertical-align":"middle"},
          innerHTML: _ctx.preicon
        }, null, 8, _hoisted_2))
      : _createCommentVNode("", true),
    (_ctx.cornerIcon)
      ? (_openBlock(), _createElementBlock("img", {
          key: 1,
          src: _ctx.cornerIcon,
          class: "absolute -top-5 -right-5 w-12 h-12",
          alt: "Corner icon"
        }, null, 8, _hoisted_3))
      : _createCommentVNode("", true),
    _createTextVNode(" " + _toDisplayString(_ctx.rawtext ? _ctx.rawtext : _ctx.$t(_ctx.text, {name: _ctx.state.nickname.value})) + " ", 1),
    (_ctx.posticon)
      ? (_openBlock(), _createElementBlock("span", {
          key: 2,
          class: "ml-2",
          style: {"line-height":"1","vertical-align":"middle"},
          innerHTML: _ctx.posticon
        }, null, 8, _hoisted_4))
      : _createCommentVNode("", true)
  ], 10, _hoisted_1))
}